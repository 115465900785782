.wowGameContainer {
  position: relative;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  /* width: 1280px;
  height: 640px; */

  width: 1200px;
  height: 627px;

  background-color: var(--black);
}

.mainGameInfo {
  display: flex;
  align-items: center;
  flex: 1;

  padding: 0 54px;
  gap: 48px;

  border: 6px solid var(--black);
}

.avatarContainer {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 204px;
  height: 204px;

  background-color: var(--black);
}

.avatar {
  width: 192px;
  height: 192px;
}

.covenantIcon {
  position: absolute;
  z-index: 2;
  right: -40px;
  bottom: -40px;

  width: auto;
  height: 112px;
}

.avatar > * {
  border-radius: 0 !important;
}

.gameInfo {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.login {
  font-size: 64px;
  font-weight: 860;
  line-height: 76px;

  color: var(--black);
}

.class {
  font-size: 56px;
  font-weight: 590;
  line-height: 66px;

  opacity: 0.48;
  color: var(--black);
}

.logoIcon {
  position: absolute;
  top: 0;
  right: 0;

  /* background: rgb(40, 241, 79);
  background: linear-gradient(90deg, rgba(40, 241, 79, 1) 0%, rgba(255, 255, 255, 1) 100%); */
}

.additionalGameInfo {
  display: flex;
  align-items: flex-end;

  height: 318px;
  padding: 0 80px 80px;
  gap: 112px;
}

.characteristic {
  display: flex;
  flex-direction: column;
  gap: 26px;

  color: var(--white);
}

.characteristicContent {
  font-size: 64px;
  font-weight: 590;
  line-height: 76px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  min-width: 96px;
  height: 96px;
  padding: 0 12px;

  border: 6px solid var(--white);
  border-radius: 4px;
}

.characteristicLabel {
  font-size: 48px;
  font-weight: 590;
  line-height: 58px;
}

.battlenetIcon {
  width: 56px;
  height: 56px;
  fill: var(--white);
}

.blackLine {
  position: absolute;
  top: 0;
  right: 0;

  width: 6px;
  height: 640px;

  background-color: var(--black);
}

.faction {
  display: flex;
  align-items: center;
}

.factionIcon {
  flex-shrink: 0;

  margin-right: 22px;

  opacity: 0.48;
  fill: var(--black);
}

.region {
  margin-left: 11px;
}
