.container {
  display: flex;
  align-items: center;

  cursor: pointer;
}

.toggleContainer {
  width: 28px;
  height: 16px;
  padding: 2px;

  border-radius: 100px;
  background-color: var(--white-10);

  transition: background-color 100ms ease;
}

.toggleEnabled {
  background-color: var(--green);
}

.toggleAnchor {
  width: 12px;
  height: 12px;

  border-radius: 100px;
  background-color: var(--white);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);

  transition: transform 100ms ease;
}

.toggleEnabled > .toggleAnchor {
  transform: translateX(12px);
}

.labelText {
  line-height: 16px;

  margin-right: 4px;

  user-select: none;

  opacity: 0.64;
}
