.container {
  position: relative;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  width: 100%;
  max-width: 230px;
}

.cardInfo {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1px;
  padding: 12px;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--dark-12);
}

.infoIcons {
  display: flex;
  gap: 12px;

  margin-right: 16px;
}

.infoIcon {
  width: 40px;
  height: 40px;
}

.bgIcon {
  position: absolute;
  top: 16px;
  right: 16px;

  height: 80%;
  object-fit: contain;

  opacity: 0.4;

  transform: rotate(15deg);
}

.contextIcon > svg {
  fill: #ffffffa0;

  width: 20px;
  height: 20px;

  transition: color 150ms ease;
}

.contextIcon:hover {
  background-color: #ffffff60;
}

.contextIcon:hover > svg {
  fill: var(--white);
}

.inviteButton {
  z-index: 10;

  margin-left: auto;
}

.inviteIcon {
  position: absolute;
  top: 0;
  right: 30px;

  width: 15px;
  height: 30px;

  cursor: pointer;

  opacity: 0.4;
}

.editIcon {
  position: absolute;
  top: 0;
  right: 8px;

  width: 15px;
  height: 30px;

  cursor: pointer;

  opacity: 0.4;
}

.inviteIcon:hover,
.editIcon:hover {
  opacity: 1;
}

.addNewContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  margin-bottom: 16px !important;
  padding: 4px 8px;

  cursor: pointer;

  border-radius: 8px;
  background-color: var(--white-10);
  gap: 8px;
}

.containerCursorPointer {
  cursor: pointer;
}

.thumbnailWrapper {
  z-index: 1;
  gap: 12px;

  display: flex;
  align-items: center;

  min-width: 0;
}

.battletag {
  color: var(--white-40);
}

.allDetailsSection {
  display: flex;
  align-items: flex-end;

  padding: 12px;

  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: var(--dark-12);
}

.allDetails {
  display: flex;
  flex-direction: column;
}

.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.previewDetails {
  align-items: flex-start;
  flex-direction: column;
}

.previewDescription {
  font-size: 46px;
  line-height: 50px;

  margin-top: 30px;
}

.detailsCharacter {
  display: flex;
  gap: 16px;
}

.detailsServer {
  display: flex;
}

.detailsServer > * {
  color: var(--black-10);
}

.detailsServer > * > svg {
  fill: var(--black-10);
}

.defaultCharacterIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  background-color: var(--yellow);
}

.defaultCharacterIcon > svg {
  width: 18px;
  height: 18px;
}

.wowAvatar {
  width: 40px;
  height: 40px;

  border-radius: 50%;
}

.wowRoleIcon {
  width: 16px;
  height: 16px;
  fill: var(--dark-48);
}

/* @media (min-width: 1366px) and (max-width: 1920px) {
  .infoIcons {
    margin-right: 8px;
  }

  .infoIcon,
  .wowAvatar {
    width: 36px;
    height: 36px;
  }

  .thumbnailWrapper {
    gap: 8px;
  }

  .details {
    align-items: flex-start;
  }

  /* Removes last visible separator to break a line *//*
  .detailsCharacter > *:nth-last-child(2) > svg {
    display: none;
  }
} */

.cardList > * {
  margin-bottom: 8px;
}

.cardListSeparator {
  margin-top: 8px;
  margin-bottom: 8px;

  color: var(--white-48);
}

.gameList {
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--game-card-width));
  grid-auto-rows: minmax(var(--game-card-height), auto);

  flex-wrap: wrap;
  gap: 12px;
}

.shareIconWrapper {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;

  width: 30px;
  height: 30px;

  cursor: pointer;
}

.shareIcon {
  height: 18px;
  stroke: var(--white);

  opacity: 0.4;

  transition: fill 150ms ease;
}

.shareIconWrapper:hover > .shareIcon {
  opacity: 1;
}

.characteristic {
  display: flex;
  gap: 4px;

  align-items: center;

  color: var(--white);
}

.fadedCharacteristic {
  opacity: 0.4;
}

.characteristicContent {
  font-size: 11px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  min-width: 17px;
  height: 17px;
  padding: 1px 3px;

  border: 1px solid var(--white);
  border-radius: 2px;
}

.characteristicLabel {
  font-size: 11px;
  font-weight: 500;
}

.avatarContainer {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: content-box;
  width: 44px;
  height: 44px;

  border: 2px solid;
  background-color: transparent;
}

.avatar {
  width: 42px;
  height: 42px;
}

.covenantIcon {
  position: absolute;
  z-index: 2;
  right: 35px;
  bottom: -12px;

  width: auto;
  height: 24px;
}

.avatar > * {
  border-radius: 0 !important;
}

.faction {
  display: flex;
  align-items: center;
}

.factionIcon {
  flex-shrink: 0;

  width: auto;
  height: 12px;
  margin-right: 5px;

  fill: var(--white-48);
}

.class {
  font-size: 11px;
  font-weight: normal;
  line-height: 13px;

  color: var(--white-48);
}

.login {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;

  color: var(--white);
}

.gameInfo {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}

.deleted {
  opacity: 0.48;
  box-shadow: var(--inset-shadow);
}
