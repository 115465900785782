/* .mainProfilePreview {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100vh;
} */

.gameContainer {
  position: relative;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  /* width: 100%; */
  width: 1200px;
  /* height: 100%; */
  height: 627px;
  padding: 33px 30px;

  /* border-radius: 32px; */
  background-color: var(--black-50);
}

.cardInfo {
  position: relative;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  margin-bottom: 10px;
}

.infoIcons {
  display: flex;
  align-items: center;

  margin-right: 16px;
  gap: 16px;
}

.infoIcon {
  width: 104px;
  height: 104px;
}

.classIcon {
  width: 48px;
  height: 48px;
}

.bgIcon {
  position: absolute;
  top: 16px;
  right: 16px;

  height: 80%;
  object-fit: contain;

  opacity: 0.4;

  transform: rotate(15deg);
}

.contextIcon {
  position: absolute;
  z-index: 10;
  top: 4px;
  right: 0;

  display: flex;
  align-items: center;

  padding: 4px;

  cursor: pointer;

  border-radius: 4px;

  transition: background-color 150ms ease;
}

.contextIcon > svg {
  fill: #ffffffa0;

  width: 20px;
  height: 20px;

  transition: color 150ms ease;
}

.contextIcon:hover {
  background-color: #ffffff60;
}

.contextIcon:hover > svg {
  fill: var(--white);
}

.addNewContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 80px;
  padding: 8px;

  cursor: pointer;

  border-radius: 12px;
  background-color: var(--martinique);
  gap: 8px;
}

.containerCursorPointer {
  cursor: pointer;
}

.thumbnailWrapper {
  z-index: 1;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  min-width: 0;
  margin-top: 32px;
}

.rowInfo {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 16px;
}

.rowInfo > * {
  font-size: 60px;
  line-height: 72px;
}

.login {
  display: flex;
  align-items: center;

  margin-left: 10px;
}

.battletag {
  font-size: 60px;
  line-height: 72px;

  color: var(--white-40);
}

.details {
  display: flex;
  align-items: center;

  margin-top: 32px;
}

.detailsServer {
  display: flex;
}

.detailsServer > * {
  color: var(--black-10);
}

.detailsServer > * > svg {
  fill: var(--black-10);
}

.defaultCharacterIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;

  background-color: var(--yellow);
}

.defaultCharacterIcon > svg {
  width: 18px;
  height: 18px;
}

.wowAvatar {
  width: 40px;
  height: 40px;

  border-radius: 50%;
}

.gameIcon {
  top: -55px;
  right: -130px;

  flex-shrink: 0;

  height: 810px;
}

.login > * {
  font-size: 60px !important;
  line-height: 72px !important;
}

.cardFields {
  font-size: 52px !important;
  line-height: 72px !important;
}
