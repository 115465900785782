.previewContainer {
  position: relative;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  width: 1200px;
  height: 627px;

  background-color: var(--white);
}

.logo {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.logoImage {
  flex-grow: 1;

  min-height: 90px;
  margin-bottom: 13px;
}

.avatarWrapper {
  display: flex;

  width: fit-content;

  border: 6px solid var(--white);
}

.avatar {
  width: 168px;
  height: 168px;

  border-radius: 0;
}

.avatar > * {
  width: 168px;
  height: 168px;

  border-radius: 0;
}

.userInfoWrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;

  padding: 42px 51px;

  border: 6px solid var(--dark);
  border-bottom: none;
  background-image: url('https://storage.googleapis.com/lfgcdn/assets/bg/lost-ark-preview.webp');
  background-position: 0% 20%;
  background-size: cover;
}

.userInfo {
  display: flex;
  flex-direction: column;

  margin-left: 37px;
  gap: 7px;
}

.username {
  font-size: 60px !important;
  font-weight: 860 !important;
  line-height: 72px !important;
}

.gameInfoWrapper {
  display: grid;

  height: 333px;
  padding: 54px 58px;

  border: 6px solid var(--dark);
  grid-template-columns: repeat(3, 1fr);
  row-gap: 60px;

  border-top: none;
}

.lostArkGame {
  display: flex;
  gap: 22px;

  align-items: center;
}

.lostArkGameInfo {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.lostArkClass {
  width: 75px;
  height: 75px;
}

.lostArkGameMainInfo {
  font-size: 30px !important;
  font-weight: 590 !important;
  line-height: 36px !important;

  overflow-x: hidden;

  max-width: 228px;

  text-overflow: ellipsis;

  color: var(--dark);
}

.lostArkGameSecondaryInfo {
  font-size: 30px !important;
  font-weight: 400 !important;
  line-height: 36px !important;

  color: var(--dark-48);
}

.params {
  display: flex;
  gap: 14px;
}

.previewParam:not(:first-child):before {
  display: block;

  width: 9px;
  height: 9px;
  margin-right: 14px;

  content: '';

  opacity: 0.4;
  border-radius: 50%;
  background-color: var(--white);
}

.previewParam {
  font-size: 42px !important;
  line-height: 54px !important;

  display: flex;
  align-items: center;

  opacity: 0.64;
  color: var(--white);
}
