.slotContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.wowAvatar {
  box-sizing: content-box;
  width: 48px;
  height: 48px;
  margin-top: 4px;
  margin-bottom: 8px; /* becaue content-box */

  border: 1px solid transparent;
  border-radius: 3px;
  outline: 2px solid var(--black);
}

.descriptionText {
  margin-top: 2px;

  text-align: center;

  color: var(--white-48);
}

.statsContainer {
  display: flex;
  justify-content: space-evenly;

  width: 100%;
  margin-top: 12px;
}

.selectCharachter {
  width: 12px;
  height: 12px;
  margin-left: 2px;

  opacity: 0.48;
}

.charNameSelect {
  display: flex;
  align-items: center;

  margin-top: 10px;

  cursor: pointer;
}

.charNameSelect:hover > .selectCharachter {
  opacity: 1;
}

.badgeValue {
  font-size: 11px;
  line-height: 13px;

  margin-bottom: 2px;
}

.badgeLabel {
  font-size: 11px;
  line-height: 13px;
}

.wowAvatarWrapper {
  position: relative;
}

.covenantIcon {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: -10px;

  width: auto;
  height: 20px;
}

.lostArkClassIcon {
  width: 48px;
  height: 48px;
  margin-bottom: 8px;

  border-radius: 16px;
}

.lostArkContainer {
  padding-bottom: 20px;
}

.lostArkSeeBuilds {
  font-weight: 400 !important;

  margin-top: 4px;
  margin-bottom: 4px;

  cursor: pointer;

  color: var(--dark-48);
  border-bottom: 1px dotted var(--dark-32);
}

.lostArkSeeBuilds:hover {
  color: var(--white);
  border-bottom: 1px dotted #5550;
}

.lolRanksContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 100%;
  margin: 4px 4px 10px;
}

.lolRanksContainer > * {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.lolHr {
  width: calc(100% - 24px);
  height: 1px;
  margin: 0 12px;

  color: transparent;
  border-top: 1px solid var(--dark-20);
}

.thumbnailContainer {
  display: flex;
  align-items: center;
}

.thumbnailDescriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 12px;
  gap: 2px;
}
