.gameCardContainer {
  position: relative;

  display: flex;
  flex-direction: column;

  width: 179px;
  height: 100%;

  border-radius: 12px;
  background-color: var(--dark-12);
  box-shadow: var(--inset-shadow);
}

.gameCardContainerDisabled {
  opacity: 0.48;
}

.gameRoleContainer {
  position: relative;

  display: flex;
  align-items: center;

  height: 28px;
  padding: 8px 13px;
  padding-bottom: 0;
}

.iconsContainer {
  position: relative;

  display: flex;
  align-items: center;
}

.severalIcons {
  margin-right: 14px;
}

.gameRoleIcon {
  border: 1px solid var(--dark-12);
  background-color: var(--dark-12);
}

.gameRoleIcon:nth-child(2) {
  position: absolute;
  left: 14px;
}

.cardParams {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
  margin-top: 8px;
  margin-bottom: 10px;
  padding: 0 12px;
  gap: 2px;
}

.hiddenParam {
  display: none;
}

.onHoverCard:hover > .visibleParam {
  display: none;
}

.onHoverCard:hover > .hiddenParam {
  display: block;
}

.onHoverCard {
  cursor: pointer;
}

.onHoverCard:hover > .paramText {
  color: var(--dark-red);
}

.emptyParams {
  height: 93px;
}

.cardParam {
  display: flex;
  justify-content: space-between;

  color: var(--dark-48);
}

.cardParam:first-child {
  color: var(--dark-64);
}

.paramText {
  color: inherit;
}

.wildRiftParams {
  margin-top: 14px;
}

.wowParams {
  margin-top: 10px;
}

.cardBody {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.descriptionText {
  margin-top: 2px;

  text-align: center;

  color: var(--white-48);
}

.wildriftChampions {
  display: flex;

  width: 100%;
  height: 52px;
  margin-top: 10px;
  margin-bottom: 8px;
}

.championIconWrapper {
  position: absolute;

  width: 52px;
  height: 52px;

  border-radius: 50%;
  background-size: cover;
}

.championImage {
  width: 100%;
  height: 100%;

  border: 2px solid var(--dark-12);
  border-radius: 50%;
}

.footerUser {
  display: flex;
  align-items: center;

  height: 51px;
  margin: 0 16px;

  border-top: 1px solid var(--dark-20);
}

.footerUserAvatar {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;

  cursor: pointer;
}

.footerUserInfo {
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;

  margin: 0 8px;
}

.userAvatar {
  width: 24px;
  height: 24px;
}

.gameLogin {
  overflow-x: hidden;
  overflow-y: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--dark-48);
}

.copyIcon {
  width: 16px;
  height: 16px;

  opacity: 0.48;
}
