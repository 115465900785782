.cardBody {
  display: flex;
  align-items: center;
  flex-direction: column;

  height: 228px;
  padding-top: 49px;
}

.avatar {
  width: 48px;
  height: 48px;
  margin-bottom: 8px;
}
