.container {
  display: flex;
  align-items: center;
}

.container > a {
  color: inherit;

  transition: color 150ms ease;
}

.container > a:hover {
  color: var(--yellow);
}

.divider {
  margin: 0 8px;

  opacity: 0.3;
}

.container:last-child > .divider {
  display: none;
}
