.previewContainer {
  position: relative;

  display: flex;
  overflow: hidden;
  flex-direction: column;

  width: 1200px; /* 1.875 */
  height: 627px; /* 1.95 */

  background-color: var(--dark);
}

.logo {
  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
}

.logoImage {
  flex-grow: 1;

  min-height: 90px;
  margin-bottom: 13px;
}

.rankedEmblem {
  width: auto;
  height: 120px;
  margin-bottom: 4px;
}

.rank {
  font-size: 22px;
  line-height: 26px;

  opacity: 0.64;
  color: var(--white);
}

.userInfoWrapper {
  display: flex;
  flex-grow: 1;

  border: 6px solid var(--dark);
  border-bottom: none;
  background-position: 0% 20%;
  /* background-image: url('https://storage.googleapis.com/lfgcdn/assets/bg/wildrift-preview.png'); */
  background-size: cover;
}

.userInfoContainer {
  display: flex;

  width: 100%;
  padding: 39px 54px;

  background: rgba(0, 0, 0, 0.48);
  mix-blend-mode: normal;
}

.userInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: var(--white);
}

.gameName {
  font-size: 60px;
  font-weight: 860;
  line-height: 71px;

  margin-bottom: 7px;
}

.tagName {
  font-size: 45px;
  line-height: 58px;

  opacity: 0.64;
}

.avatarContainer {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 180px;
  height: 180px;
  margin-right: 37px;

  background-color: var(--white);
}

.avatar {
  width: 168px;
  height: 168px;
}

.avatar > * {
  border-radius: 0 !important;
}

.championsContainer {
  display: flex;
  justify-content: space-between;

  padding: 39px 54px;

  border: 6px solid var(--dark);
  background: linear-gradient(0deg, #41444b, #41444b);
}

.championInfo {
  margin-left: 30px;
}

.championInfoGames {
  font-size: 30px;
  line-height: 35.625px;

  color: var(--white-48);
}

.championInfoWinrate {
  font-size: 45px;
  line-height: 54.375px;
}

.statsContainer {
  position: relative;
  gap: 72px;

  display: flex;
  align-items: center;

  padding: 30px 54px;

  border: 6px solid var(--dark);
  border-top: none;
  background: #41444b;
}

.stata {
  font-size: 45px;
  line-height: 58px;

  display: flex;
  align-items: center;
  flex-shrink: 0;

  width: fit-content;

  color: var(--white);
}

.champion {
  display: flex;
  align-items: center;
}

.championContainer {
  position: relative;

  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;

  width: 105px;
  height: 105px;

  background-size: cover;
}

.championImage {
  width: 100%;
  height: 100%;

  border: 6px solid var(--white);
}

.poweredBy {
  position: absolute;
  top: 0;
  right: 20px;

  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;

  height: 100%;
}

.poweredByText {
  font-size: 23px;
  line-height: 28px;

  margin-bottom: 5px;

  color: var(--white-48);
}

.poweredByImg {
  width: 183px;
}

.dot {
  width: 12px;
  height: 12px;
  margin: 0 15px;

  border-radius: 50%;
  background-color: var(--dark-32);
}
